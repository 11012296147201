import { Box, Button, Typography } from "@mui/material";
import DashboardLayout from "layout";
import DetailComponent from "pages/SearchPNR/Details";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { InputEditor } from "./components/InputEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editPNR, searchPNR } from "services/pnr";
import { LoadingState } from "components/LoadingState";
import { useToast } from "components/Toast";

const SearchPNRComponent = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const queryClient = useQueryClient();
  const [pnrCode, setPnrCode] = useState("");
  const [editing, setEditing] = useState(false);
  const { showToast } = useToast();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["pnr", query],
    queryFn: () => (query ? searchPNR(query) : null),
    enabled: !!query,
  });

  const editPNRMutation = useMutation({
    mutationFn: (newPNR: string) =>
      editPNR(query || "", {
        db_id: data?.data?.processsedFlightData?.[0]?.db_id,
        new_pnr: newPNR,
      }),
    onSuccess: () => {
      showToast("Operation successful!", "success");
      queryClient.invalidateQueries({ queryKey: ["pnr", query] });
      setEditing(false);
      setPnrCode("");
    },
    onError: (error) => {
      console.error("Failed to update PNR:", error);
      showToast(error?.message, "error");
    },
  });

  const handleSave = () => {
    if (!pnrCode) return;
    editPNRMutation.mutate(pnrCode);
  };

  if (isLoading) {
    return <LoadingState message="Fetching PNR details..." />;
  }
  return (
    <DashboardLayout nextSideBar>
      {!editing && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize="24px" fontWeight="600" variant="h5">
            Customer Flight Details
          </Typography>

          <Button
            sx={{ bgcolor: "#379E66", borderRadius: "8px", fontSize: "14px" }}
            variant="contained"
            onClick={() => setEditing(true)}
          >
            Add PNR to Trip
          </Button>
        </Box>
      )}
      {editing && (
        <>
          <Typography fontSize="24px" fontWeight="600" variant="h5">
            Add PNR
          </Typography>
          <InputEditor
            value={pnrCode}
            setValue={setPnrCode}
            onSave={handleSave}
            onCancel={() => {
              setEditing(false);
              // setPnrCode("");
            }}
            saveButtonText="Save PNR"
            label="PNR Code"
            placeholder="Enter PNR Code"
            error={editPNRMutation.isError ? "Failed to update PNR" : ""}
            disabled={editPNRMutation.isPending}
          />
        </>
      )}
      <DetailComponent
        data={data}
        isLoading={isLoading}
        isError={isError}
        pnrCode={query || ""}
      />
    </DashboardLayout>
  );
};

export default SearchPNRComponent;
