export const formatFlightDate = (dateString: string) => {
  if (!dateString || dateString.length !== 6) return "";
  const day = dateString.slice(0, 2);
  const month = dateString.slice(2, 4);
  const year = "20" + dateString.slice(4, 6);

  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
  const monthName = date.toLocaleDateString("en-US", { month: "long" });

  return `${weekday} ${parseInt(day)} ${monthName} ${year}`;
};

export const formatDateWithSlash = (dateString: string) => {
  if (!dateString || dateString.length !== 6) return "";
  return `${dateString.slice(0, 2)}/${dateString.slice(
    2,
    4
  )}/20${dateString.slice(4, 6)}`;
};

export const formatFlightTime = (timeString: string) => {
  if (!timeString || timeString.length !== 4) return "";
  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(2, 4);
  const hour24 = parseInt(hours);
  const period = hour24 >= 12 ? 'pm' : 'am';
  const hour12 = hour24 % 12 || 12;
  return `${hour12}:${minutes}${period}`;
};
