import { Box, Grid, Typography } from "@mui/material";
import { FlightDetailsProps } from "../types/flight";
import { DetailRow } from "./DetailRow";

export const FlightDetails: React.FC<FlightDetailsProps> = ({ flight }) => {
  const details = [
    { label: "Departure Date", value: flight.departureDate },
    { label: "Arrival Date", value: flight.arrivalDate },
    { label: "Departure City", value: flight.departureCity },
    { label: "Arrival City", value: flight.arrivalCity },
    { label: "Class", value: flight.classType },
    { label: "Equipment", value: flight.equipment },
    { label: "Terminal", value: flight.terminal },
    { label: "Meal", value: flight.meal },
  ];

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "600" }}>
        ✈️ {flight.airline} {flight.flightNumber}
      </Typography>

      {details.map((detail, index) => (
        <DetailRow key={index} {...detail} />
      ))}

      {/* {flight.terminal && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "14px" }}>Terminal</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography
              sx={{ color: "#344054", fontWeight: "600", fontSize: "12px" }}
            >
              {flight.terminal}
            </Typography>
          </Grid>
        </Grid>
      )}

      {flight.meal && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "14px" }}>Meal</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography
              sx={{ color: "#344054", fontWeight: "600", fontSize: "12px" }}
            >
              {flight.meal}
            </Typography>
          </Grid>
        </Grid>
      )} */}
    </Box>
  );
};
