import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://www.staging.move9ja.com/api/v1/",
});

http.interceptors.request.use(
  async (req) => {
    req.headers.Authorization = localStorage.getItem("token") ?? "";
    return req;
  },
  async (error) => {
    Promise.reject(error);
  }
);

export default http;
