import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

interface BackButtonProps {
  onBack?: () => void;
}

export default function BackButton({ onBack }: BackButtonProps) {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <Button
      startIcon={<ChevronLeft />}
      onClick={handleBack}
      sx={{
        color: "#667185",
        textTransform: "none",
        py: "12px",
        borderTop: "1px solid #E4E7EC",
        borderBottom: "1px solid #E4E7EC",
        width: "100%",
        justifyContent: "flex-start",
        pl: 5,
      }}
    >
      Back
    </Button>
  );
}
