import { Box, InputBase, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SearchBarPNR: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue.trim()) {
      navigate(`/dashboard/searchpnr?q=${searchValue}`);
    }
  };

  return (
    <Box
      bgcolor="white"
      sx={{
        p: 3,
        mb: 4,
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <InputBase
        placeholder="Search PNR..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        sx={{
          flex: 1,
          bgcolor: "#F9FAFB",
          py: "10px",
          px: 2,
          borderRadius: "8px",
          fontSize: "14px",
          color: "#667085",
          outline: "none",
          border: "none",
          boxShadow: "none",
          "&:focus": {
            outline: "none",
            border: "none",
            boxShadow: "none",
          },
        }}
      />
      <Button
        sx={{
          bgcolor: "black",
          color: "white",
          borderRadius: "4px",
          textTransform: "none",
          px: 4,
          ml: 2,
          py: "9px",
          fontSize: "14px",
          fontWeight: "600",
          "&:hover": { bgcolor: "#333" },
          "&.Mui-disabled": {
            color: "white",
            bgcolor: "#666",
            opacity: 0.7,
          },
        }}
        onClick={handleSearch}
        disabled={!searchValue.trim()}
      >
        Search
      </Button>
    </Box>
  );
};

export default SearchBarPNR;
