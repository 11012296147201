import { Grid, Typography } from "@mui/material";
import { DetailRowProps } from "../types/flight";

export const DetailRow: React.FC<DetailRowProps> = ({ label, value }) => 
  value ? (
    <Grid container spacing={4} sx={{ mb: 0.5 }}>
      <Grid item xs={6}>
        <Typography sx={{ color: "#475467", fontSize: "14px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography
          sx={{ color: "#344054", fontWeight: "600", fontSize: "12px" }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  ) : null;
