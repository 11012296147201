import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchBarPNR from "./SearchBarPNR";
import { useMutation } from "@tanstack/react-query";
import { sendEmail, SendEmailPayload } from "services/pnr";
import { useToast } from "components/Toast";

const emailFormSchema = yup.object().shape({
  pnrcode: yup.string().required("PNR Code is required"),
  subject: yup.string().required("Subject is required"),
  type: yup
    .string()
    .oneOf(
      [
        "reminder",
        "booking_confirmation",
        "flight_reschedule",
        "client_reschedule",
        "cancellation",
      ],
      "Invalid email type"
    )
    .required("Type is required"),
  message: yup.string().optional(),
});

const MailForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm<SendEmailPayload>({
    resolver: yupResolver(emailFormSchema),
    defaultValues: {
      pnrcode: "",
      subject: "",
      type: "reminder",
      message: "",
    },
    mode: "onChange",
  });

  const { showToast } = useToast();

  const emailMutation = useMutation({
    mutationFn: sendEmail,
    onSuccess: () => {
      showToast("Email sent successfully!", "success");
      reset();
    },

    onError: (error) => {
      showToast("Failed to send email. Please try again.", "error");
      console.error("Failed to send email:", error);
    },
  });

  const selectedType = watch("type");

  const onSubmit = (data: SendEmailPayload) => {
    if (!isValid) return;

    const payload = {
      pnrcode: data.pnrcode,
      subject: data.subject,
      message: data.message || "",
      type: data.type,
    };

    emailMutation.mutate(payload);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography fontSize="24px" fontWeight="600" variant="h5" sx={{ mb: 3 }}>
        Customer Email Message
      </Typography>
      <SearchBarPNR />
      <Box bgcolor="white" sx={{ p: 5 }}>
        <Typography fontSize="20px" fontWeight="700" sx={{ mb: 3 }}>
          Customer's Details
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                shrink
                sx={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                PNR Code
              </InputLabel>
              <Controller
                name="pnrcode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="238476230"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: "#F9F9F9",
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: "10px",
                      color: "#98A2B3",
                      fontSize: "14px",
                    }}
                    error={!!errors.pnrcode}
                    helperText={errors.pnrcode ? errors.pnrcode.message : ""}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                shrink
                sx={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Subject
              </InputLabel>
              <Controller
                name="subject"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Title"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: "#F9F9F9",
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: "10px",
                      color: "#98A2B3",
                      fontSize: "14px",
                    }}
                    error={!!errors.subject}
                    helperText={errors.subject?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                shrink
                sx={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Type
              </InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue="reminder"
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                    placeholder="Enter type"
                    sx={{
                      bgcolor: "#F9F9F9",
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: "10px",
                      color: "#98A2B3",
                      fontSize: "14px",
                      border: "none",
                      boxShadow: "none",
                      "& fieldset": { border: "none" },
                      "&:focus": {
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      },
                    }}
                    error={!!errors.type}
                  >
                    <MenuItem value="reminder">Check-in Reminder</MenuItem>
                    <MenuItem value="booking_confirmation">
                      Booking Confirmation
                    </MenuItem>
                    <MenuItem value="flight_reschedule">
                      Flight Reschedule
                    </MenuItem>
                    <MenuItem value="client_reschedule">
                      Client Reschedule
                    </MenuItem>
                    <MenuItem value="cancellation">Cancellation</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ gridColumn: "1 / -1" }}
            >
              <InputLabel
                shrink
                sx={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Custom Message (Optional)
              </InputLabel>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Enter your message"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: "#F9F9F9",
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: "10px",
                      color: "#98A2B3",
                      fontSize: "14px",
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || emailMutation.isPending}
              sx={{
                mt: 2,
                py: 1,
                px: 3,
                borderRadius: "10px",
                fontWeight: "600",
                fontSize: "14px",
                maxWidth: "300px",
                margin: "0 auto",
                width: "100%",
                opacity: !isValid ? 0.7 : 1,
              }}
            >
              {emailMutation.isPending ? "Sending..." : "Send"}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default MailForm;
