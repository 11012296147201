import { Box, CircularProgress, Typography, Fade } from "@mui/material";
import { keyframes } from "@mui/system";

interface LoadingStateProps {
  message?: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingState = ({ message = "Loading..." }: LoadingStateProps) => {
  return (
    <Fade in={true} timeout={600}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          gap: 2,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          zIndex: "500000",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          animation: `${fadeIn} 0.3s ease-in-out`,
        }}
      >
        <CircularProgress 
          sx={{ 
            color: "#379E66",
            animation: `${spin} 1s linear infinite`,
          }} 
        />
        <Typography 
          sx={{ 
            color: "#344054",
            animation: `${fadeIn} 0.3s ease-in-out`,
          }}
        >
          {message}
        </Typography>
      </Box>
    </Fade>
  );
};
