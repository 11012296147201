import { Button, Grid, InputBase, Typography } from "@mui/material";

interface InputEditorProps {
  value: string;
  setValue: (value: string) => void;
  onSave: () => void;
  onCancel: () => void;
  saveButtonText: string;
  label: string;
  placeholder: string;
  error?: string;
  disabled?: boolean;
}

export const InputEditor: React.FC<InputEditorProps> = ({
  value,
  setValue,
  onSave,
  onCancel,
  saveButtonText,
  label,
  placeholder,
  error,
  disabled
}) => (
  <Grid container spacing={2} sx={{ mt: 0 }}>
    <Grid item xs={12}>
      <Typography
        sx={{
          color: "#344054",
          fontWeight: "600",
          fontSize: "12px",
          bgcolor: "white",
          p: 1,
        }}
      >
        {label}
      </Typography>
      <InputBase
        fullWidth
        value={value}
        disabled={disabled}
        sx={{
          bgColor: "#F9FAFB",
          py: "8px",
          px: 2,
          borderRadius: "8px",
          fontSize: "14px",
          color: "#667085",
          border: `1px solid ${error ? "#d32f2f" : "#1018280D"}`,
          opacity: disabled ? 0.7 : 1,
          '&.Mui-disabled': {
            color: '#667085',
            WebkitTextFillColor: '#667085',
          }
        }}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder || label}
      />
      {error && (
        <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </Grid>
    <Grid item xs={12} sx={{ display: "flex", gap: 1 }}>
      <Button
        variant="contained"
        color="success"
        disabled={disabled}
        sx={{
          bgcolor: "#379E66",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "600",
          '&.Mui-disabled': {
            bgcolor: '#379E66',
            opacity: 0.7,
          }
        }}
        onClick={onSave}
      >
        {saveButtonText}
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={disabled}
        sx={{
          bgcolor: "#DA4733",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "600",
          '&.Mui-disabled': {
            bgcolor: '#DA4733',
            opacity: 0.7,
          }
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Grid>
  </Grid>
);
