import http from "services/http";

export interface SendEmailPayload {
  pnrcode: string;
  subject: string;
  type:
    | "reminder"
    | "booking_confirmation"
    | "cancellation"
    | "flight_reschedule"
    | "client_reschedule";
  message?: string;
  airport_pnr?: string;
}

export const sendEmail = async (payload: SendEmailPayload) => {
  const response = await http.post("/admin/email/reminder", payload);
  return response.data;
};

interface PNRDetails {
  pnrcode: string;
  flightDetails: {
    departure: string;
    arrival: string;
    departureTime: string;
    arrivalTime: string;
    // Add other flight details as needed
  };
  passengerDetails: {
    name: string;
    email: string;
    phone: string;
    // Add other passenger details as needed
  };
}

export const searchPNR = async (pnrCode: string): Promise<any> => {
  const response = await http.get(`/admin/email/findpnr/${pnrCode}`);
  return response.data;
};

interface EditPNRPayload {
  db_id: string;
  new_pnr?: string;
  FlightLegNumber?: string;
  terminal?: string;
  meal?: string;
}

export const editPNR = async (pnrCode: string, payload: EditPNRPayload) => {
  const response = await http.post(`admin/email/editpnr/${pnrCode}`, payload);
  return response.data;
};
