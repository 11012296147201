import DashboardLayout from "layout";
import MailForm from "./Mailform";

const SendMailComponent = () => {
  return (
    <DashboardLayout nextSideBar>
      <MailForm />
    </DashboardLayout>
  );
};

export default SendMailComponent;
