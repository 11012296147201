import { useState } from "react";
import { Card, Typography, Button, Box, Grid } from "@mui/material";
import { InputEditor } from "./components/InputEditor";
import { TerminalState } from "./types/flight";
import { DetailRow } from "./components/DetailRow";
import { FlightDetails } from "./components/FlightDetails";
import { editPNR } from "services/pnr";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  formatDateWithSlash,
  formatFlightDate,
  formatFlightTime,
} from "utils/helpers";
import { useToast } from "components/Toast";

interface PassengerDetailsProps {
  pnrCode: string;
  data: any;
  isLoading?: boolean;
  isError?: boolean;
}

export default function PassengerDetails({
  pnrCode,
  data,
}: PassengerDetailsProps) {
  const queryClient = useQueryClient();
  const [terminals, setTerminals] = useState<TerminalState>({});
  const [meals, setMeals] = useState<TerminalState>({});
  const [editingDay, setEditingDay] = useState<string | null>(null);
  const [editingMealDay, setEditingMealDay] = useState<string | null>(null);
  const [terminalNumber, setTerminalNumber] = useState("");
  const [meal, setMeal] = useState("");
  const [mealError, setMealError] = useState<string>("");
  const { showToast } = useToast();

  const flightDetails = data?.data?.processsedFlightData || [];
  const firstFlight = flightDetails[0] || {};
  const flightLegs = firstFlight?.flightmodel?.FlightLegs || [];
  const editPNRMutation = useMutation({
    mutationFn: (payload: {
      type: "terminal" | "meal";
      value: string;
      flightLegNumber: string;
    }) =>
      editPNR(pnrCode, {
        db_id: firstFlight?.db_id,
        FlightLegNumber: payload.flightLegNumber,
        ...(payload.type === "terminal"
          ? { terminal: payload.value }
          : { meal: payload.value }),
      }),
    onSuccess: () => {
      showToast("Operation successful!", "success");
      queryClient.invalidateQueries({ queryKey: ["pnr", pnrCode] });
    },
    onError: (error) => {
      console.error("Failed to update PNR:", error);
      showToast(error?.message, "error");
    },
  });
  const handleSaveMeal = () => {
    if (!meal) {
      setMealError("Meal is required");
      return;
    }

    if (editingMealDay && meal) {
      const flightLeg = flightLegs.find(
        (leg: any) => formatFlightDate(leg.DepartureDate) === editingMealDay
      );

      if (flightLeg) {
        editPNRMutation.mutate(
          {
            type: "meal",
            value: meal,
            flightLegNumber: flightLeg.FlightLegNumber,
          },
          {
            onSuccess: () => {
              setMeals((prev) => ({
                ...prev,
                [editingMealDay]: [...(prev[editingMealDay] || []), meal],
              }));
              setEditingMealDay(null);
              setMeal("");
              setMealError("");
            },
          }
        );
      }
    }
  };
  const passengerDetails = [
    { label: "Name", value: firstFlight?.customername || "N/A" },
    { label: "Email", value: firstFlight?.customeremail || "N/A" },
    { label: "Telephone", value: firstFlight?.customerphone || "N/A" },
    { label: "Agency", value: "Move9ja" },
    {
      label: "Booking Status",
      value:
        firstFlight?.bookingstatus
          ?.split("_")
          .map(
            (word: any) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ") || "N/A",
    },
    ...(firstFlight?.flightmodel?.FreeBaggage?.Weight
      ? [
          {
            label: "Baggage",
            value: `${firstFlight.flightmodel.FreeBaggage.BagCount} Bags, ${firstFlight.flightmodel.FreeBaggage.Weight}kg`,
          },
        ]
      : []),
    // { label: "Equipment", value: "Boeing 737 All Series Passenger" },
  ];
  const formattedFlightData = flightLegs.map((leg: any) => ({
    date: formatFlightDate(leg.DepartureDate),
    flights: [
      {
        airline: leg.OperatingCarrierName,
        flightNumber: `${leg.MarketingCarrier} ${leg.FlightNumber}`,
        departureDate: `${formatDateWithSlash(
          leg.DepartureDate
        )}, ${formatFlightTime(leg.DepartureTime)}`,
        arrivalDate: `${formatDateWithSlash(
          leg.ArrivalDate
        )}, ${formatFlightTime(leg.ArrivalTime)}`,
        departureCity: `${leg.DepartureName}`,
        arrivalCity: `${leg.DestinationName}`,
        bookingStatus: firstFlight?.bookingstatus || "N/A",
        classType: leg.CabinClassName,
        duration: leg.Duration,
        layover: leg.Layover || "",
        layoverDuration: leg.LayoverDuration,
        equipment: `${leg.Aircraft} - ${leg.EquipmentType}`,
        terminal: leg.terminal,
        meal: leg.Meal,
      },
    ],
  }));
  const handleSaveTerminal = () => {
    if (editingDay && terminalNumber) {
      const flightLeg = flightLegs.find(
        (leg: any) => formatFlightDate(leg.DepartureDate) === editingDay
      );
      console.log("flightLeg", flightLeg);

      if (flightLeg) {
        editPNRMutation.mutate({
          type: "terminal",
          value: terminalNumber,
          flightLegNumber: flightLeg.FlightLegNumber,
        });
      }

      setTerminals((prev) => ({
        ...prev,
        [editingDay]: [...(prev[editingDay] || []), terminalNumber],
      }));
      setEditingDay(null);
      setTerminalNumber("");
    }
  };

  return (
    <Card sx={{ p: 3, borderRadius: "12px", mt: 3 }}>
      <Typography
        sx={{ fontSize: "16px", fontWeight: "600" }}
        variant="h6"
        gutterBottom
      >
        Passenger Details
      </Typography>
      {/* {pnrCode && (
        <Box sx={{ borderBottom: "1px solid #ccc", pb: 1, mb: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px" }}>New PNR</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ color: "#344054", fontWeight: "600", fontSize: "12px" }}
              >
                {pnrCode}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )} */}

      {passengerDetails.map((detail, index) => (
        <DetailRow key={index} {...detail} />
      ))}

      {formattedFlightData.map((item: any, index: number) => (
        <div key={index}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600" }}
              variant="h6"
              gutterBottom
            >
              {item?.date}
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
              {!editingDay && !editingMealDay && (
                <Button
                  sx={{ fontSize: "14px", fontWeight: "600" }}
                  variant="contained"
                  onClick={() => setEditingDay(item?.date)}
                >
                  Add Terminal
                </Button>
              )}
              {!editingMealDay && !editingDay && (
                <Button
                  sx={{ fontSize: "14px", fontWeight: "600" }}
                  variant="outlined"
                  onClick={() => setEditingMealDay(item?.date)}
                >
                  Add Meal
                </Button>
              )}
            </Box>
          </Box>

          {editingDay === item?.date && (
            <InputEditor
              value={terminalNumber}
              label="Terminal Number"
              placeholder="Enter Terminal Number"
              saveButtonText="Save Terminal"
              setValue={setTerminalNumber}
              onSave={handleSaveTerminal}
              onCancel={() => {
                setEditingDay(null);
                setTerminalNumber("");
              }}
              error={editPNRMutation.isError ? "Failed to update Terminal" : ""}
              disabled={editPNRMutation.isPending}
            />
          )}

          {editingMealDay === item?.date && (
            <InputEditor
              value={meal}
              label="Meal"
              placeholder="Enter meal preference"
              saveButtonText="Save Meal"
              setValue={(value) => {
                setMeal(value);
                setMealError("");
              }}
              onSave={handleSaveMeal}
              onCancel={() => {
                setEditingMealDay(null);
                setMeal("");
                setMealError("");
              }}
              error={editPNRMutation.isError ? "Failed to update Meal" : ""}
              disabled={editPNRMutation.isPending}
            />
          )}

          {item?.flights.map((flight: any, flightIndex: number) => (
            <Box key={flightIndex} sx={{ mb: 2 }}>
              <FlightDetails
                flight={{
                  ...flight,
                  // terminal: terminals[item?.date]
                  //   ? terminals[item?.date].join(", ")
                  //   : undefined,
                  // meal: meals[item?.date]
                  //   ? meals[item?.date].join(", ")
                  //   : undefined,
                }}
              />
            </Box>
          ))}
        </div>
      ))}
    </Card>
  );
}
